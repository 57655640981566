import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const STORAGE_KEY = 'userMsg'

// 从localStorage获取初始用户数据
const initUserMsg = () => {
  try {
    const userMsg = localStorage.getItem(STORAGE_KEY)
    return userMsg ? JSON.parse(userMsg) : {}
  } catch (e) {
    console.error('Failed to parse userMsg from localStorage:', e)
    return {}
  }
}

// 登录验证
export default new Vuex.Store({
    state: {
        userMsg: initUserMsg(),
        dataNeedsRefresh: false,
    },
    mutations: {
        setDataNeedsRefresh(state, payload) {
            console.log('Mutation setDataNeedsRefresh called with payload:', payload);
            state.dataNeedsRefresh = payload;
        },
        SET_USER_MSG(state, userMsg) {
            state.userMsg = userMsg
            // 将更新后的用户信息保存到localStorage
            localStorage.setItem(STORAGE_KEY, JSON.stringify(userMsg))
        },
    },
    actions: {
        updateUserMsg({ commit }, userMsg) {
            commit('SET_USER_MSG', userMsg)
        },
    },
})
